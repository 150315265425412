<template>
  <div class="application-section"><window v-for="app in apps" :key="app.id" :setting="app" /></div>
  <div class="taskbar-section">
    <div class="task-bar">
      <div class="start" @click="startHandle">
        <img src="@/assets/icon/start.png" alt="">
      </div>
      <div class="task-list">
        <div v-for="task in tasklist" :key="task.id" class="task-item" :class="{ actived: task.focus }" @click="onClick(task.id)">
          <img :src="task.icon" alt="">
        </div>
      </div>
    </div>
    <div class="region">
      <!-- <el-select v-model="region" placeholder="请选择域" style="width: 240px" @change="handleChange">
        <el-option
          v-for="item in regionOptions"
          :key="item.id"
          :label="item.regionName"
          :value="item.id"
        />
      </el-select> -->
      <el-dropdown>
        <div class="region-content">
          <svg-icon icon-class="position-black" />
          {{ remark }}
          <svg-icon icon-class="arrow" class="arrow" />
        </div>
        <template #dropdown>
          <el-dropdown-menu class="region-drop">
            <div class="region-title">
              <div>区域 Region</div>
            </div>
            <el-dropdown-item
              v-for="item in regionOptions"
              :key="item.id"
              :class="{'regionActive':regionId==item.id}"
              @click="handleChange(item.id, item.regionName, item.remark)"
            >
              <svg-icon v-if="regionId==item.id" icon-class="position-blue" />
              <svg-icon v-else icon-class="position" />
              {{ item.remark }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="tool">
      <el-tooltip
        class="box-item"
        effect="light"
        content="用户手册"
        placement="bottom-end"
      >
        <div class="user-manual" @click="openUsersManualDrawer">
          <svg-icon icon-class="usermanual" />
        </div>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="light"
        content="问题反馈"
        placement="bottom-end"
      >
        <div class="problem" @click="openProblemDrawer">
          <svg-icon icon-class="problem1" />
        </div>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="light"
        content="消息通知"
        placement="bottom-end"
      >
        <div style="position:relative;" @click="checkTip">
          <svg-icon icon-class="message" />
          <div v-show="showUnread" class="unread">{{ unreadNum }}</div>
        </div>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="light"
        content="用户信息"
        placement="bottom-end"
      >
        <div class="user-info" @click="checkCost">
          <!-- <i class="el-icon-user-solid" /> -->
          <svg-icon icon-class="account" />
        </div>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="light"
        content="退出登录"
        placement="bottom-end"
      >
        <div class="off" @click="logout">
          <img src="@/assets/icon/off.png" alt="">
        </div>
      </el-tooltip>
    </div>
    <div class="cost">
      <el-card v-show="costVisible" class="box-card cost-pop" :body-style="{ width: '260px', padding: '0' }">
        <div class="info">
          <img :src="avatar" alt="">
          <div>{{ name }}</div>
          <div v-show="auth==1" class="svg-box"><svg-icon icon-class="auth" /></div>
        </div>
        <div class="box-split" />
        <div class="cost-pop-top">
          <div class="money">
            <div class="title recharge-box">
              <div>账户余额</div>
              <div class="recharge" @click="goRecharge">充值</div>
            </div>
            <div class="val">￥{{ accountData.balance }}</div>
          </div>
          <div class="box-split" />
          <div class="money">
            <div class="title">消费累计</div>
            <div class="val">￥{{ accountData.comeoutMoney }}</div>
          </div>
          <div class="box-split" />
          <div class="money">
            <div class="title">积分余额
              <el-tooltip effect="light" content="每消费100元获得1积分" placement="top">
                <svg-icon icon-class="help-circle" style="margin-left: 2px;" />
              </el-tooltip>
            </div>
            <div class="val">{{ userPoint }}</div>
          </div>
          <div class="box-split" />
          <div class="flex-box">
            <div class="personal" @click="goPersonal">个人信息</div>
            <div class="detail" @click="goDetail">账户详情</div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 应用 -->
    <el-drawer
      v-model="drawer"
      title=""
      size="100%"
      :direction="direction"
    >
      <div class="flex-style">
        <div v-for="(item, i) in appList" :key="i">
          <div ref="main" class="application applicationArea" @click="openApp(item)" @contextmenu.prevent="rightClick(item,$event)">
            <div class="img-box">
              <img :src="item.icon">
            </div>
            <div class="name">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 消息 -->
    <el-drawer
      v-model="msgDrawer"
      title="消息通知"
      direction="rtl"
      class="msg-drawer"
      :before-close="handleCloseMsg"
    >
      <div v-show="isNoRead" class="drawer-footer">
        <el-button @click="getAllMessageHandle">全部消息</el-button>
        <el-button class="clear-all" @click="readAllMessage">标为已读</el-button>
      </div>
      <div v-show="!isNoRead" class="drawer-footer">
        <el-button @click="getNoReadMessageHandle">未读消息</el-button>
      </div>
      <div v-show="msgData.length===0" class="no-message">
        <img src="@/assets/icon/no-msg.png" alt="">
        <div>暂无消息</div>
      </div>
      <div v-show="msgData.length!==0" v-loading="msgLoading">
        <div v-infinite-scroll="msgLoadMore" infinite-scroll-immediate="false" infinite-scroll-disabled="disabled">
          <div v-for="(item, i) in msgData" :key="i" @click="detailHandle(item, i)">
            <div class="msg-box" :class="[item.ifRead===2?'msgRead': 'msgUnRead']">
              <div class="box-header">
                <div>
                  <i v-if="item.ifRead===2" class="el-icon-warning" style="font-size: 20px;color:#909399;" />
                  <i v-else class="el-icon-warning" style="font-size: 20px;color:#437aec;" />
                  <span>{{ item.typeName }}</span>
                </div>
                <div>
                  <i v-if="item.ifRead===2" :class="item.isShow == true ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="font-size: 20px;color:#909399;" />
                  <i v-else :class="item.isShow == true ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="font-size: 20px;color:#437aec;" />
                </div>
              </div>
              <div class="box-time">{{ item.updateTime }}</div>
              <div class="box-title">{{ item.msgTitle }}</div>
              <div class="ql-editor" v-html="item.msgContent" />
            </div>

          </div>
          <p v-if="moreLoading" style="text-align: center;margin-top: 10px;font-size: 14px;">加载中...</p>
          <p v-if="noMore" style="text-align: center;margin-top: 10px;font-size: 14px;">没有更多了</p>
        </div>
      </div>
    </el-drawer>
    <div v-show="menuVisible" id="contextmenu" class="taskBarMenu">
      <div v-if="!link" class="contextmenu-item" @click="addDesktop(CurrentRow)">
        <svg-icon icon-class="start" />
        添加
      </div>
      <div class="contextmenu-item" @click="uninstallApp(CurrentRow)">
        <svg-icon icon-class="uninstall" />
        卸载
      </div>
    </div>
    <!-- 问题反馈抽屉 -->
    <problem-feedback ref="problem" />
    <users-manual ref="usersManual" />
  </div>
</template>

<script>
import { ElMessageBox, ElMessage } from 'element-plus'
import { isLink, uninstallApp } from '@/api/application'
import { getAccountMessage } from '@/api/ehpc'
import { getRegionList } from '@/api/region'
import { getInfo, queryUserPoint } from '@/api/user'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { getUnReadSortMessage, setMessageRead, getAllMessage } from '@/api/message'
// import { queryPersonApp } from '@/api/application'
import Window from './Window.vue'
import REGISTER from '../register/index.js'
import { mapGetters } from 'vuex'
import ProblemFeedback from '@/views/ProblemFeedback'
import UsersManual from '@/views/UsersManual'

export default {
  components: {
    Window,
    ProblemFeedback,
    UsersManual
  },
  inject: ['reload'],
  props: ['userInfo'],
  data() {
    return {
      // 多域
      // regionName: '选择域',
      region: '',
      // regionOptions: [],
      // regionId: null,
      auth: null,
      isDrawer: false,
      noMore: false,
      unNoMore: false,
      allNoMore: false,
      moreLoading: false,
      busyScroll: false,
      PageSize: 18, // 每页显示
      PageNum: 0, // 当前页
      PageTotal: 0, // 总条数
      isNoRead: null,
      msgData: [],
      msgLoading: true,
      msgContent: '',
      msgIDList: [],
      isShowDetail: false,
      showUnread: false,
      unreadNum: 0,
      tipVisible: false,
      msgDrawer: false,
      link: true,
      menuVisible: false,
      drawer: false,
      costVisible: false,
      direction: 'ttb',
      accountData: {
        balance: 0,
        comeoutMoney: 0
      },
      menulist: [],
      // appList: [],
      setting: {},
      // 时间信息
      timeItem: {
        time: new Date().toLocaleTimeString(),
        date: new Date().toLocaleDateString()
      },
      userPoint: 0
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'name'
    ]),
    appList() {
      var applications = this.$store.state.core.applications
      var appList = []
      applications.forEach((item, i) => {
        if (item.visible !== 0) {
          appList.push(item)
        }
      })
      return appList
    },
    apps() {
      const apps = []
      if (this.$store.state.core.tasks.length > 0) {
        this.$store.state.core.tasks.forEach(app => {
          if (app != null) {
            apps.push(app)
          }
        })
        return apps.sort((a, b) => {
          return a.date - b.date
        })
      } else {
        return []
      }
    },
    tasklist() {
      const tasks = []
      this.$store.state.core.tasks.forEach((task, index) => {
        if (task != null) {
          if (index == 0 && task.min == false) {
            task.focus = true
          } else {
            task.focus = false
          }
          tasks.push(task)
        }
      })
      return tasks.sort((a, b) => { return a.date - b.date })
    },
    itemWidth() {
      if (this.$store.state.core.tasks.length < 5) {
        return 100
      } else {
        const ww = document.body.clientWidth * 0.95
        return Math.floor(ww / this.$store.state.core.tasks.length)
      }
    },
    showmenu() {
      return this.$store.state.core.startMenu
    },
    disabled() {
      return this.moreLoading || this.noMore
    },
    usersManualCount() {
      var usersManualCount = null
      usersManualCount = this.$store.state.core.usersManualCount
      return usersManualCount
    },
    regionOptions() {
      return this.$store.state.core.regionOptions
    },
    regionName() {
      return this.$store.state.core.regionName
    },
    remark() {
      return this.$store.state.core.remark
    },
    regionId() {
      if (this.$store.state.core.regionId) {
        this.getAccountMessage()
        this.getNoReadMessageHandle()
      }
      return this.$store.state.core.regionId
    }
  },
  watch: {
    userInfo: {
      handler(val, oldVal) {
        this.costVisible = val
      },
      deep: true
    },
    usersManualCount: {
      handler(val, oldVal) {
        this.$refs.usersManual.openUsersManualDrawer()
      },
      deep: true
    },
    msgData: {
      handler(val, oldVal) {
        console.log('changed')
      },
      deep: true
    },
    msgDrawer(n, o) {
      console.log(n)
      if (!n) {
        // this.reload()
        if (this.msgIDList.length !== 0) {
          this.msgIDList = [...new Set(this.msgIDList)]
          console.log(this.msgIDList)
          const data = {
            msgIDList: this.msgIDList
          }
          setMessageRead(data).then(res => {
            this.getNoReadMessageHandle()
          })
        } else {
          this.getNoReadMessageHandle()
        }
        this.isNoRead = true
      }
    }
  },
  created() {
    // this.appList=this.$store.state.core.applications
    const hours = new Date().getHours()
    const minutes = new Date().getMinutes()
    this.timeItem.time = hours + ':' + minutes
    this.timer()
    REGISTER.application.forEach(item => {
      const object = {}
      if (item.sublist) {
        object.sublist = []
        item.sublist.forEach(o => {
          const sub = {}
          sub.id = o.id
          sub.name = o.name
          sub.icon = o.icon
          object.sublist.push(sub)
        })
        object.subhidden = true
      }
      object.id = item.id
      object.name = item.name
      object.icon = item.icon
      this.menulist.push(object)
    })
  },
  mounted() {
    this.getInfo()
    queryUserPoint().then(res => {
      if (res.meta.status === 200) {
        this.userPoint = res.data.nowPoints
      }
    })
    this.$nextTick(() => {
      document.getElementsByClassName('el-drawer')[0].addEventListener('click', this.deawerClose)
      // document.getElementsByClassName('msg-drawer')[0].addEventListener('click', this.msgClose)
      // document.getElementsByClassName('desktop')[0].addEventListener('click', this.closeCost)
    })
  },
  beforeUnmount() {
    document.getElementsByClassName('el-drawer')[0].removeEventListener('click', this.deawerClose)
    // document.getElementsByClassName('msg-drawer')[0].removeEventListener('click', this.msgClose)
    // document.getElementsByClassName('desktop')[0].removeEventListener('click', this.closeCost)
  },
  methods: {
    // 获取域列表
    getRegionList() {
      getRegionList().then((res) => {
        if (res.meta.status === 200) {
          this.regionOptions = res.data
          if (window.localStorage.regionId === undefined) {
            window.localStorage.regionId = this.regionOptions[0].id
            window.localStorage.regionValue = window.localStorage.regionId
            window.localStorage.regionName = this.regionOptions[0].regionName
            this.regionName = this.regionOptions[0].regionName
            this.regionId = this.regionOptions[0].id
          }
          res.data.forEach(item => {
            if (item.id == window.localStorage.regionValue) {
              this.regionName = item.regionName
              this.regionId = item.id
            }
          })
        }
      })
    },
    handleChange(val, name, remark) {
      window.localStorage.regionName = name
      window.localStorage.remark = remark
      this.regionName = window.localStorage.regionName
      this.regionId = val
      this.remark = window.localStorage.remark
      window.localStorage.regionId = val
      window.localStorage.regionValue = window.localStorage.regionId
      window.location.reload()
    },
    handleCloseMsg() {
      this.msgDrawer = false
    },
    msgClose(e) {
      const self = this
      if (this.$refs.main && !document.getElementsByClassName('msg-drawer')[0].contains(e.target)) {
        if (self.msgDrawer == true) {
          self.msgDrawer = false
        }
      }
    },
    // 获取info接口
    getInfo() {
      getInfo().then((res) => {
        if (res.meta.status === 200) {
          this.auth = res.data.auth
        }
      })
    },
    // 打开用户手册
    openUsersManualDrawer() {
      const obj = {
        usersManualCount: null,
        id: null
      }
      this.$store.dispatch('core/usersManual', obj)
      this.$refs.usersManual.openUsersManualDrawer()
    },
    // 打开问题反馈抽屉
    openProblemDrawer() {
      this.$refs.problem.openProblemDrawer()
    },
    // 消息-加载更多
    msgLoadMore() {
      if (this.isNoRead === null) {
        return
      }
      this.moreLoading = true
      if (!this.busyScroll) {
        this.noMore = false
        const that = this
        setTimeout(() => {
          if (that.isNoRead) {
            that.PageNum += 1
            that.getNoReadMessage()
          } else {
            that.PageNum += 1
            that.getAllMessage()
          }
        }, 500)
      } else {
        this.noMore = true
        this.moreLoading = false
      }
    },
    // 查看消息
    checkTip() {
      this.msgIDList = []
      const arr = document.getElementsByClassName('ql-editor')
      if (arr.length !== 0) {
        for (var i = 0; i < arr.length; i++) {
          arr[i].style.display = 'none'
        }
      }
      this.msgDrawer = true
      this.isNoRead = true
      // this.getNoReadMessage()
    },
    closeTip() {
      if (this.tipVisible) {
        this.tipVisible = false
      }
    },
    // 消息标为已读
    readAllMessage() {
      if (this.msgData.length === 0) {
        ElMessage.warning('您没有未读消息')
        return
      }
      const arr = []
      this.msgData.forEach((e) => {
        arr.push(e.msgId)
      })
      this.$nextTick(() => {
        const data = {
          msgIDList: arr
        }
        setMessageRead(data).then(res => {
          this.getNoReadMessageHandle()
        })
      })
    },
    // 获取全部消息
    getAllMessageHandle() {
      this.msgLoading = true
      this.isNoRead = false
      this.noMore = false
      this.moreLoading = false
      this.PageNum = 1
      this.msgData = []
      this.$nextTick(() => {
        this.getAllMessage()
      })
    },
    getAllMessage() {
      const data = {
        sortBy: 'updateTime',
        pagenum: this.PageNum,
        pagesize: this.PageSize,
        orderBy: 'DESC'
      }
      getAllMessage(data).then(res => {
        this.PageTotal = res.data.totalelements
        this.moreLoading = false
        const Base64 = require('js-base64').Base64
        res.data.content.forEach((e) => {
          e.isShow = false
          e.msgContent = Base64.decode(e.msgContent)
        })
        this.$nextTick(() => {
          this.msgData = this.msgData.concat(res.data.content)
          if (res.data.content.length == 0) {
            this.noMore = true
            this.busyScroll = true
            this.msgLoading = false
          } else {
            this.busyScroll = false
            this.msgLoading = false
          }
        })
      })
    },
    getNoReadMessageHandle() {
      this.msgLoading = true
      this.isNoRead = true
      this.noMore = false
      this.moreLoading = false
      this.PageNum = 1
      this.msgData = []
      this.$nextTick(() => {
        this.getNoReadMessage()
      })
    },
    // 获取未读消息
    getNoReadMessage() {
      const data = {
        sortBy: 'updateTime',
        pagenum: this.PageNum,
        pagesize: this.PageSize,
        orderBy: 'DESC'
      }
      getUnReadSortMessage(data).then(res => {
        this.PageTotal = res.data.totalelements
        if (res.data.totalelements > 0) {
          this.unreadNum = res.data.totalelements
          this.showUnread = true
        } else {
          this.showUnread = false
        }
        this.moreLoading = false
        const Base64 = require('js-base64').Base64
        res.data.content.forEach((e) => {
          e.isShow = false
          e.msgContent = Base64.decode(e.msgContent)
        })
        this.$nextTick(() => {
          this.msgData = this.msgData.concat(res.data.content)
          if (res.data.content.length == 0) {
            this.noMore = true
            this.busyScroll = true
            this.msgLoading = false
          } else {
            this.busyScroll = false
            this.msgLoading = false
          }
        })
      })
    },
    // 消息详情
    detailHandle(item, i) {
      console.log(item)
      item.isShow = !item.isShow
      if (item.isShow === false) {
        document.getElementsByClassName('ql-editor')[i].style.display = 'none'
      } else {
        document.getElementsByClassName('ql-editor')[i].style.display = 'block'
        if (this.isNoRead) {
          this.msgIDList.push(item.msgId)
        }
        if (item.ifRead === 1) {
          this.msgIDList.push(item.msgId)
        }
      }
    },
    // 充值
    goRecharge() {
      this.$store.dispatch('core/openApp', 2946147018625071)
      this.costVisible = false
      this.$emit('userInfoChange', this.costVisible)
    },
    // 个人信息
    goPersonal() {
      this.$store.dispatch('core/openApp', 8889162036467445)
      this.costVisible = false
      this.$emit('userInfoChange', this.costVisible)
    },
    // 跳转打开账户详情
    goDetail() {
      this.$store.dispatch('core/openApp', 8889162036467441)
      this.costVisible = false
      this.$emit('userInfoChange', this.costVisible)
    },
    // 获取账户金额 消费累计消息
    getAccountMessage() {
      getAccountMessage().then(res => {
        if (res.meta.status === 200) {
          // if (res.data.balance < 0) {
          localStorage.balance = res.data.balance
          // } else {
          //   localStorage.balance = true
          // }
          this.accountData = res.data
          this.accountData.balance = (this.accountData.balance / 1000).toFixed(3).replace(/\.?0+$/, '')
          this.accountData.comeoutMoney = (this.accountData.comeoutMoney / 1000).toFixed(3).replace(/\.?0+$/, '')
        }
      })
    },
    // closeCost(e) {
    //   const self = this
    //   if (!document.getElementsByClassName('cost')[0].contains(e.target) && !document.getElementsByClassName('user-info')[0].contains(e.target)) {
    //     self.costVisible = false
    //     this.$emit('userInfoChange', this.costVisible)
    //   }
    // },
    checkCost() {
      if (!this.costVisible) {
        this.getAccountMessage()
      }
      this.costVisible = !this.costVisible
      this.$emit('userInfoChange', this.costVisible)
    },
    // 右键
    rightClick(row, event) {
      this.link = row.link
      this.testModeCode = row.testModeCode
      this.menuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault() // 关闭浏览器右键默认事件
      this.CurrentRow = row
      var menu = document.querySelector('.taskBarMenu')
      this.styleMenu(menu, event)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      window.removeEventListener('click', this.foo) // 关掉监听，
    },
    styleMenu(menu, event) {
      if (this.link) {
        menu.style.height = 31.5 + 'px'
      } else {
        menu.style.height = 63 + 'px'
      }
      var y = event.clientY
      var x = event.clientX
      menu.style.left = x + 'px'
      window.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      menu.style.top = y + 'px'
    },
    // 添加到桌面
    addDesktop(row) {
      const data = {
        linkId: row.linkId,
        link: 'true'
      }
      isLink(data).then((response) => {
        if (response.meta.status === 200) {
          ElMessage.success('添加成功')
          this.$store.commit('core/APP_INIT')
        } else {
          ElMessage.error(response.meta.msg)
        }
      })
    },
    // 卸载
    uninstallApp(row) {
      uninstallApp(row.id).then((response) => {
        if (response.meta.status === 204) {
          ElMessage.success('卸载成功')
          this.$store.dispatch('core/close', row.id)
          this.$store.dispatch('core/close', 3149252963594628)
          this.$store.commit('core/APP_INIT')
        }
      })
    },
    deawerClose(e) {
      const self = this
      if (this.$refs.main && !document.getElementsByClassName('applicationArea')[0].contains(e.target)) {
        if (self.drawer == true) {
          self.menuVisible = false
          self.drawer = false
        }
      }
    },
    // 开始
    startHandle() {
      this.drawer = true
      // queryPersonApp().then((response) => {
      // 	this.appList = response.data
      // })
    },
    openApp(item) {
      this.drawer = false
      this.$store.dispatch('core/openApp', item.id)
    },
    // 注销
    async logout() {
      ElMessageBox.confirm('确定要注销吗？', '注销确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // type: "warning",
        // center: true,
      })
        .then(async() => {
          await this.$store.dispatch('user/logout')
          this.$router.push('/')
        })
        .catch(() => {
          // ElMessage({
          //   type: "info",
          //   message: "已取消"
          // });
        })
    },
    // 获取时间
    timer() {
      const mythis = this
      setInterval(() => {
        const hours = new Date().getHours()
        const minute = new Date().getMinutes()
        let minutes
        if (minute < 10) {
          minutes = '0' + minute
        } else {
          minutes = minute
        }
        mythis.timeItem.date = new Date().toLocaleDateString()
        mythis.timeItem.time = hours + ':' + minutes
      }, 1000)
    },
    onClickItem(object) {
      // console.log('object====>' + JSON.stringify(object));
      if (object.sublist) {
        object.subhidden = !object.subhidden
      } else {
        this.$store.dispatch('core/openApp', object.id)
        this.$store.commit('core/SELECT_ICON', '')
      }
    },
    onLink(id) {
      this.$store.dispatch('core/openApp', id)
      this.$store.commit('core/SELECT_ICON', '')
    },
    onClick(id) {
      this.$store.dispatch('core/clickTaskbar', id)
    },
    onStart() {
      this.$store.commit('core/OPEN_START_MENU')
    },
    onSidebar() {
      this.$store.dispatch('core/sidebarSwitch')
    },
    onLockScreen() {
      // this.$store.dispatch('session/lockScreen');
    }
  }
}
</script>

<style lang="less" scoped>
@import '../global.less';
.unread{
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: red;
  top:20%;
  right:33%;
  border-radius: 50%;
  color: white;
  line-height: 18px;
  font-size: 10px;
  text-align: center;
}
.no-message{
  padding-top: 50px;
  text-align: center;
  color: #606266;
  font-size: 16px;
  img {
    margin-bottom: 20px;
    width: 300px;
  }
}
::v-deep .msg-drawer{
  .el-drawer__close-btn{
    display: block;
  }
  .el-icon svg{
    width: 16px;
    height: 16px;
  }
}
// 全部消息
.drawer-footer{
  position: fixed;
  background-color: #fff;
  width: 330px;
  bottom: 0;
  right: 0;
  padding: 5px 10px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  z-index: 9999;
  ::v-deep .el-button{
    padding: 0 15px;
    border-radius: 20px;
    min-height: 30px;
    color: #fff;
  }
  .clear-all{
    color: #333;
    background-color: #E8EEF3 !important;
    border: 1px solid #E8EEF3;
  }
}
::v-deep .el-drawer__body{
  padding-bottom: 45px;
}
.msg-box:hover{
  background-color: #E8ECF2;
}
.msg-box{
  cursor: pointer;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  background-color: #F0F4FA;
  line-height: 18px;
  font-size: 12px;
  .ql-editor{
    display: none;
    padding-left: 0;
    color: #A0A0A0;
  }
  .box-header{
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #437aec;
    padding-bottom: 15px;
    i {
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}
.msgRead{
  background-color: #F7F7F7;
  .box-header,
  .box-time,
  .box-title{
    color: #909399;
  }
}
.msgRead:hover{
  background-color: #E7E7E7;
}
.taskBarMenu {
  position: fixed;
  background-color: #fff;
  width: 160px;
	padding: 0 10px;
  height: 63px;
  font-size: 12px;
  color: #313a46;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #E8EEF3;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  white-space: nowrap;
  z-index: 10000;
	.svg-icon{
		width: 16px;
		height: 16px;
		vertical-align: middle;
		margin-right: 11px;
	}
}
.cost {
  font-size: 14px;
  .info{
    display: flex;
    align-items: center;
    padding: 9px 0 9px 16px;
    color: #000;
    font-size: 18px;
    .svg-box{
      margin-left: 8px;
      margin-top: 7px;
      .svg-icon{
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
  img{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .box-split{
    width: 100%;
    border-bottom: 1px solid #EEEEEE;
  }
  .flex-box{
            color: #165DFF;
            font-size: 16px;
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
          }
      .cost-pop {
        border-radius: 10px;
        position: absolute;
        z-index: 999;
        top: 43px;
        right: 35px;
        .title, .val {
          line-height: 1;
        }
        .title {
          margin-bottom: 10px;
          color: #000;
          font-size: 14px;
        }
        .val{
          color: #165DFF;
          font-size: 18px;
        }
        .cost-pop-top {
          padding: 0 16px;
          .money{
            padding: 16px 0 12px;
            .recharge-box{
              display: flex;
              justify-content: space-between;
              .recharge{
                color: #165DFF;
                cursor: pointer;
              }
            }
          }
          .personal{
            cursor: pointer;
          }
          .detail{
            cursor: pointer;
          }
        }
      }
    }
.el-button{
	background-color: #437aec !important;
	border-color: #437aec;
}
::v-deep .rtl {
  color: #333 !important;
  background-color: #fff !important;
  width: 330px !important;
}
::v-deep .rtl .el-drawer__header{
    color: #000;
    text-align: center;
    margin-bottom: 0;
    padding: 6px 0;
    padding-right: 20px;
    border-bottom: 1px solid #eee;
    font-size: 18px;
  }
::v-deep .el-drawer.rtl {
  height: calc(100% - 36px) !important;
  top: 36px !important;
}
::v-deep .el-drawer{
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	overflow: auto;
}
::v-deep .el-icon{
	font-size: 26px;
	color: #fff;
}
::v-deep .el-drawer__close-btn{
	display: none;
}
::v-deep .el-message-box{
	padding-bottom: 0;
}
.flex-style {
        display: flex;
        flex-wrap: wrap;
        min-width: 300px;
				padding-left: 300px;
				padding-right: 200px;
				.application {
        text-align: center;
        width: 180px;
				// padding-right: 100px;
        .img-box {
          img {
            width: 60px;
            height: 60px;
            border-radius: 20px;
            box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
          }
        }
        .name {
          width: 100%;
          padding-top: 10px;
          font-size: 12px;
          margin-bottom: 30px;
          font-weight: 500;
					color: #fff;
        }
      }
      }
.taskbar-section {
	width: 100%;
	height: 36px;
	line-height: 36px;
	// background: linear-gradient(90deg, #E9E9E9, #FFFFFF);
	background-color: rgba(255,255,255,.8);
	// opacity: 0.8;
	position: absolute;
	top: 0;
	bottom: auto !important;
	left: 0;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: space-between;
  .region{
    padding-right: 16px;
    padding-top: 7px;
    width: 1800px;
    text-align: right;
    .region-content{
      display: flex;
      align-items: center;
      border-radius: 4px 4px 4px 4px;
      // border: 1px solid #2C3E50;
      // background-color: #F0F4F8;
      padding: 4px 12px;
      font-weight: 500;
      font-size: 14px;
      color: #2C3E50;
      .svg-icon{
        width: 22px;
        height: 22px;
        vertical-align: middle;
        margin-right: 8px;
      }
      .arrow{
        width: 8px;
        height: 8px;
        vertical-align: middle;
        margin-left: 8px;
        margin-top: 3px;
      }
    }
    ::v-deep .el-tooltip__trigger:focus-visible{
      outline: unset;
    }
    ::v-deep .el-select__wrapper{
      background-color: transparent;
    }
    ::v-deep .el-select__wrapper.is-focused{
      background-color: transparent;
      box-shadow: none;
    }
    ::v-deep .el-select__wrapper.is-hovering:not(.is-focused){
      background-color: transparent;
      box-shadow: none;
    }
  }
	.task-bar{
		padding-left: 12px;
		display: flex;
		align-items: center;
		z-index: 1001;
		.start:hover{
			background-color: #d8dae2;
		}
		.start {
			padding: 0 15px;
			// margin-right: 10px;
			img {
				width: 20px;
				height: 20px;
			}
		}
		.task-list {
			overflow: hidden;
			white-space: nowrap;
			text-overflow:ellipsis;
			width: calc(100vw - 630px);
			height: 36px;
			line-height: 36px;
			.active {
				padding-top: 15px;
			}
			.task-item:hover{
				background-color: #d8dae2;
			}
			.task-item {
				display: inline-flex;
				padding: 10px 15px 9px;
				img {
					width: 20px;
					height: 20px;
					opacity: 1;
				}
			}
		}
	}
	.tool {
		z-index: 1001;
		display: flex;
		align-items: center;
    // div{
    //   padding: 0 22px;
    // }
    .problem{
      padding: 0 22px;
    }
    .user-manual{
      padding: 0;
      margin-top: 2px;
      // padding-right: 22px;
    }
    .svg-icon{
      width: 22px;
      height: 22px;
      margin: 12px 20px 0 0;
    }
    .user-info{
      font-size: 22px;
    }
		.time-date{
			line-height: normal;
			text-align: center;
			.time{
				font-size: 16px;
				font-weight: normal;
				color: #202020;
				padding-bottom: 4px;
			}
			.date{
				font-size: 12px;
				font-weight: normal;
				color: #202020;
			}
		}
		// .user-info:hover, .off:hover{
		// 	background-color: #d8dae2;
		// }
		.user-info, .off {
			// padding-right: 22px;
			padding-left: 22px;
			img {
				width: 18px;
				height: 21px;
			}
		}
		.border-style {
			height: 24px;
			border-left: 1px solid #808080;
		}
	}
}
.dog-menu-list {
	width: 50%;
	float: left;
	background: #fff;
	border: solid 1px #365167;
	margin: 7px 0 7px 7px;
	box-shadow: 0 0 1px #fff;
	-moz-box-shadow: 0 0 1px #fff;
	-webkit-box-shadow: 0 0 1px #fff;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	max-height: 400px;
	overflow-y: auto;
	.menu-item {
		border: solid 1px transparent;
		display: block;
		padding: 3px;
		margin: 3px;
		color: #4b4b4b;
		text-align: left;
		text-decoration: none;
	}
	.menu-item:hover {
		border: solid 1px #7da2ce;
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		box-shadow: inset 0 0 1px #fff;
		-moz-box-shadow: inset 0 0 1px #fff;
		-webkit-box-shadow: inset 0 0 1px #fff;
		background-color: #cfe3fd;
		background: -moz-linear-gradient(top, #dcebfd, #c2dcfd);
		background: -webkit-gradient(linear, center top, center bottom, from(#dcebfd), to(#c2dcfd));
	}
	a img {
		border: 0;
		vertical-align: middle;
		margin: 0 5px 0 0;
	}
}

.dog-menu-links {
	width: 45%;
	float:right;
	margin: 5px;
	li.icon {
		text-align: center;
	}
	a {
		border: solid 1px transparent;
		display: block;
		margin: 5px 0;
		position: relative;
		color: #fff;
		text-decoration: none;
		min-width: 120px;
	}
	a:hover {
		border: solid 1px #000;
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		box-shadow: 0 0 1px #fff;
		-moz-box-shadow: inset 0 0 1px #fff;
		-webkit-box-shadow: inset 0 0 1px #fff;
		background-color: #658da0;
		background: -moz-linear-gradient(center left, rgba(81, 115, 132, 0.55), rgba(121, 163, 184, 0.55) 50%, rgba(81, 115, 132, 0.55));
		background: -webkit-gradient(linear, 0% 100%, 100% 100%, from(#517384), color-stop(50%, #79a3b8), to(#517384));
	}
	a span {
		padding: 5px;
		display: block;
	}
	a:hover span {
		background: -moz-linear-gradient(center top, transparent, transparent 49%, rgba(2, 37, 58, 0.5) 50%, rgba(63, 111, 135, 0.5));
		background: -webkit-gradient(
			linear,
			center top,
			center bottom,
			from(transparent),
			color-stop(49%, transparent),
			color-stop(50%, rgba(2, 37, 58, 0.5)),
			to(rgba(63, 111, 135, 0.5))
		);
	}
}
</style>
<style>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
