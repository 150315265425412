<template>
  <div
    v-loading="loading"
    class="desktop"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <!-- 进度条 -->
    <div id="unzip" class="unzip-progress" />
    <wallpaper />
    <icon-grid @loadHandle="loadHandle" />
    <Taskbar :user-info="userInfoVisible" @userInfoChange="userInfoChange" />
    <ContextMenu />
    <Sidebar :sidebar-show="sidebarShow" />
    <div class="dialog-container">
      <el-dialog
        v-model="formVisible"
        title="系统通知"
        :close-on-click-modal="false"
        :before-close="handleClose"
        width="400px"
      >
        <div class="dialogs-content">
          <el-form ref="forms" :model="forms" size="small">
            <div class="dialog-form">
              <div class="er-code">
                <img src="@/assets/jengcloud.jpg" alt="山东正云信息科技有限公司">
              </div>
              <div class="tips">您可扫码关注公众号<br>您的作业情况我们将通过公众号给您推送</div>
              <el-form-item label="" prop="isNotice">
                <el-checkbox-group v-model="forms.isNotice" @change="changeHandle">
                  <el-checkbox label="不再提醒" name="isNotice" />
                </el-checkbox-group>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <!-- 修改账号 -->
    <div class="dialog-balance-container account-dialog">
      <el-dialog
        v-model="accountVisible"
        title="修改账号"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="400px"
      >
        <div class="dialog-content">
          <!--基本信息-->
          <div class="base-info">
            <div>
              <el-form ref="accountForm" :rules="rules" :model="accountForm" size="small">
                <div class="dialog-form">
                  <el-form-item prop="account" label="账号" label-width="4em">
                    <el-input v-model="accountForm.account" placeholder="请输入新账号" />
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <el-button :loading="accountConfirm" size="small" type="primary" @click="handleConfirm">提 交</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 欠费提示 -->
    <div class="dialog-balance-container">
      <el-dialog
        v-model="balanceVisible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="400px"
      >
        <div class="dialogs-content">
          <div class="headers">
            <div class="left">
              <img src="@/assets/balance.png" alt="">
              <div>欠费通知</div>
            </div>
            <div class="recharge" @click="isFinish=true">
              <router-link target="_blank" to="/recharge">充值</router-link>
            </div>
          </div>
          <div class="tips">
            您已欠费{{ balanceData }}元，为了不影响使用体验，请及时续费
          </div>
          <div v-show="isFinish" class="recharge-finish"><span @click="rechargeFinish">充值完成</span></div>
        </div>
      </el-dialog>
    </div>
    <!-- 实名认证 -->
    <div class="dialog-balance-container">
      <el-dialog
        v-model="authVisible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="400px"
      >
        <div class="dialogs-content">
          <div class="headers">
            <div class="left">
              <img src="@/assets/balance.png" alt="">
              <div>未实名认证</div>
            </div>
          </div>
          <div v-show="!isAuth">
            <div class="tips">
              检测到您的认证信息不全，为满足法律法规和主管部门要求，保护您的合法权益，我们需要获取您的姓名和身份信息进行实名认证。为不影响您的产品体验，请您先完善信息。
            </div>
            <div class="btn-box">
              <el-button plain @click="goAuth">去认证</el-button>
            </div>
          </div>
          <div v-show="isAuth">
            <Authentication @queryAuthResult="queryAuthResult" />
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 活动弹窗 -->
    <div class="dialog-activity-container">
      <el-dialog
        v-model="activityVisible"
        :close-on-click-modal="false"
        :before-close="handleCloseActivity"
        width="580px"
      >
        <div>
          <img :src="activityImage" alt="" width="580" height="450">
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Authentication from '@/components/Authentication.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import Wallpaper from './Wallpaper.vue'
import Taskbar from './Taskbar.vue'
import ContextMenu from './ContextMenu.vue'
import IconGrid from './IconGrid.vue'
import Sidebar from './Sidebar.vue'
import { getAccountMessage } from '@/api/ehpc'
import { getInfo, userUpdateAccount, duplicatecheck, queryActivity } from '@/api/user'
import { downloadProgress } from '@/mixins/downloadProgress.js'
import { getRegionData } from '@/store/core'
import { filesList } from '@/api/file'
import store from '@/store'
export default {
  name: 'Desktop',
  components: {
    Taskbar,
    Wallpaper,
    IconGrid,
    ContextMenu,
    Sidebar,
    Authentication
  },
  mixins: [downloadProgress],
  data() {
    const validateAccount = (rule, value, callback) => {
      const reg = /^\d*([a-zA-Z-_])+\d*$/
      if (value.trim() === '') {
        callback(new Error('请输入新账号'))
      } else if (value.trim().length < 2 || value.trim().length > 50) {
        callback(new Error('账号长度应在2-50个字符之间'))
      } else if (!reg.test(value.trim())) {
        callback(new Error('请输入英文数字及_-且不能全是数字'))
      } else {
        duplicatecheck(value.trim()).then(res => {
          if (res.meta.status === 455) {
            this.accountRepeat = true
          }
          if (this.accountRepeat) {
            callback(new Error(res.meta.msg))
            this.accountRepeat = false
          } else {
            callback()
          }
        })
      }
    }
    return {
      accountRepeat: false,
      accountConfirm: false,
      accountForm: {
        account: ''
      },
      userAccount: '',
      rules: {
        account: [
          { required: true, validator: validateAccount, trigger: 'blur' }
        ]
      },
      accountVisible: false,
      isAuth: false,
      auth: null,
      activityVisible: false,
      loading: true,
      formVisible: false,
      balanceVisible: false,
      authVisible: false,
      forms: {
        isNotice: []
      },
      accountData: {
        balance: 0,
        comeoutMoney: 0
      },
      balanceData: 0,
      isFinish: false,
      currentTime: null,
      startTime: 1704729600,
      isHasActivity: false,
      activityImage: '',
      userInfoVisible: false
    }
  },
  computed: {
    sidebarShow() {
      return this.$store.state.core.sidebar
    }
  },
  created() {
    Promise.all(
      [
        getRegionData(store.state.core),
        getInfo()
      ]
    ).then(([regionData, userInfo]) => {
      if (userInfo.meta.status === 200) {
        this.auth = userInfo.data.auth
        this.userAccount = userInfo.data.account
        if (this.userAccount.substring(0, 6) === 'jcehpc') {
          this.accountVisible = true
        } else {
          this.currentTime = parseInt(new Date().getTime() / 1000) + ''
          this.$nextTick(() => {
            this.getAccountMessage()
          })
        }
        this.loading = false
        if (userInfo.data.loginCount <= 5) {
          let type = ''
          if (window.localStorage.regionId === undefined) {
            type = regionData.data[0].type
          } else {
            const region = regionData.data.find(item => {
              return item.id == window.localStorage.regionId
            })
            type = region.type
          }
          if (type == 'CPU_SLURM' || type == 'GPU_AI') {
            filesList(
              `/public/home/${userInfo.data.account}`,
              true
            ).then(res => {
              console.log('get home file list')
            })
          }
        }
      }
    })
    queryActivity().then(res => {
      if (res.meta.status === 200 && res.data) {
        this.isHasActivity = true
        this.activityImage = res.data.picture
      }
    })
  },
  beforeCreate() {
    // oncontextmenu 事件在元素中用户右击鼠标时触发并打开上下文菜单
    document.oncontextmenu = new Function('event.returnValue=false')
    // onselectstart几乎可以用于所有对象，其触发时间为目标对象被开始选中时（即选中动作刚开始，尚未实质性被选中）
    document.onselectstart = new Function('event.returnValue=false')
    this.$store.commit('core/APP_INITT')
  },
  mounted() {
    document.getElementsByClassName('desktop')[0].addEventListener('click', this.closeCost)
  },
  beforeUnmount() {
    document.getElementsByClassName('desktop')[0].removeEventListener('click', this.closeCost)
  },
  methods: {
    // 用户信息父子组件传值
    userInfoChange(val) {
      this.userInfoVisible = val
    },
    closeCost(e) {
      const self = this
      if (!document.getElementsByClassName('cost')[0].contains(e.target) && !document.getElementsByClassName('user-info')[0].contains(e.target)) {
        self.userInfoVisible = false
      }
    },
    // 确定修改账号
    handleConfirm() {
      this.accountConfirm = true
      this.$refs.accountForm.validate((valid) => {
        if (valid) {
          userUpdateAccount(this.accountForm.account).then(res => {
            if (res.meta.status === 201) {
              this.accountVisible = false
              this.accountConfirm = false
              this.$refs.accountForm.resetFields()
              ElMessage.success('修改成功')
              ElMessageBox.confirm('修改账号后您需要重新登录', '确认重新登录', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消'
              }).then(() => {
                this.$store.dispatch('user/resetToken').then(() => {
                  location.reload()
                })
              }).catch(() => {
                this.currentTime = parseInt(new Date().getTime() / 1000) + ''
                this.$nextTick(() => {
                  this.getAccountMessage()
                })
              })
            } else {
              ElMessage.error(res.meta.msg)
            }
          })
          this.accountConfirm = false
          // this.$refs.passInfoForm.resetFields()
        } else {
          this.accountConfirm = false
        }
      })
    },
    goAuth() {
      this.isAuth = true
    },
    // 查询认证
    queryAuthResult(data) {
      if (data === 'success') {
        getInfo().then((res) => {
          if (res.meta.status === 200) {
            this.auth = res.data.auth
            this.$store.commit('user/SET_AUTH', res.data.auth)
            if (res.data.auth == 1) {
              this.authVisible = false
              if (localStorage.cLoseDialog === 'false') {
                this.formVisible = false
                if (localStorage.activity === 'false') {
                  // this.activityVisible = true
                  if (this.isHasActivity) {
                    this.activityVisible = true
                  }
                  return
                } else if (localStorage.activity === 'true') {
                  this.activityVisible = false
                  return
                }
              }
              if (localStorage.isNotice === true || localStorage.isNotice === undefined) {
                this.formVisible = true
              } else {
                this.formVisible = false
                if (localStorage.activity === 'true') {
                  this.activityVisible = false
                  return
                } else if (localStorage.activity === 'false') {
                  // this.activityVisible = true
                  if (this.isHasActivity) {
                    this.activityVisible = true
                  }
                }
              }
            }
          }
        })
      }
    },
    // 获取info接口
    getInfo() {
      getInfo().then((res) => {
        if (res.meta.status === 200) {
          this.auth = res.data.auth
        }
      })
    },
    // 点击活动
    activityClick() {
      this.activityVisible = false
      localStorage.activity = 'true'
      this.$store.dispatch('core/openApp', 2946147018625071)
    },
    // 关闭
    handleCloseActivity() {
      localStorage.activity = 'true'
      this.activityVisible = false
    },
    // 充值完成
    rechargeFinish() {
      getAccountMessage().then(res => {
        if (res.meta.status === 200) {
          localStorage.balance = res.data.balance
          this.balanceData = Math.abs(res.data.balance) / 1000
          this.$nextTick(() => {
            if (localStorage.balance < 0) {
              this.balanceVisible = true
              ElMessage.warning('您仍欠费' + this.balanceData + '元，请充值！')
            } else if (localStorage.balance >= 0) {
              this.balanceVisible = false
              getInfo().then((res) => {
                if (res.meta.status === 200) {
                  this.auth = res.data.auth
                  if (this.auth != 1) {
                    this.authVisible = true
                  } else {
                    if (localStorage.cLoseDialog === 'false') {
                      this.formVisible = false
                      if (localStorage.activity === 'false') {
                        if (this.isHasActivity) {
                          this.activityVisible = true
                        }
                        return
                      } else if (localStorage.activity === 'true') {
                        this.activityVisible = false
                        return
                      }
                    }
                    if (localStorage.isNotice === true || localStorage.isNotice === undefined) {
                      this.formVisible = true
                    } else {
                      this.formVisible = false
                      if (localStorage.activity === 'true') {
                        this.activityVisible = false
                        return
                      } else if (localStorage.activity === 'false') {
                        // this.activityVisible = true
                        if (this.isHasActivity) {
                          this.activityVisible = true
                        }
                      }
                    }
                  }
                }
              })
            } else {
              if (localStorage.cLoseDialog === 'false') {
                this.formVisible = false
                if (localStorage.activity === 'false') {
                  if (this.isHasActivity) {
                    this.activityVisible = true
                  }
                  return
                } else if (localStorage.activity === 'true') {
                  this.activityVisible = false
                  return
                }
              }
              if (localStorage.isNotice === true || localStorage.isNotice === undefined) {
                this.formVisible = true
              } else {
                this.formVisible = false
                if (localStorage.activity === 'true') {
                  this.activityVisible = false
                  return
                } else if (localStorage.activity === 'false') {
                  // this.activityVisible = true
                  if (this.isHasActivity) {
                    this.activityVisible = true
                  }
                }
              }
            }
          })
        }
      })
    },
    // 获取账户金额 消费累计消息
    getAccountMessage() {
      getAccountMessage().then(res => {
        if (res.meta.status === 200) {
          localStorage.balance = res.data.balance
          this.balanceData = Math.abs(res.data.balance) / 1000
          this.$nextTick(() => {
            if (localStorage.balance < 0) {
              this.balanceVisible = true
            } else if (localStorage.balance > 0) {
              this.balanceVisible = false
              console.log(this.auth)
              if (this.auth != 1) {
                this.authVisible = true
              } else {
                if (localStorage.cLoseDialog === 'false') {
                  this.formVisible = false
                  if (localStorage.activity === 'false') {
                  // this.activityVisible = true
                    if (this.isHasActivity) {
                      this.activityVisible = true
                    }
                    return
                  } else if (localStorage.activity === 'true') {
                    this.activityVisible = false
                    return
                  }
                }
                if (localStorage.isNotice === true || localStorage.isNotice === undefined) {
                  this.formVisible = true
                } else {
                  this.formVisible = false
                  if (localStorage.activity === 'true') {
                    this.activityVisible = false
                    return
                  } else if (localStorage.activity === 'false') {
                  // this.activityVisible = true
                    if (this.isHasActivity) {
                      this.activityVisible = true
                    }
                  }
                }
              }

              // }
            } else {
              if (localStorage.cLoseDialog === 'false') {
                this.formVisible = false
                if (localStorage.activity === 'false') {
                  // this.activityVisible = true
                  if (this.isHasActivity) {
                    this.activityVisible = true
                  }
                  return
                } else if (localStorage.activity === 'true') {
                  this.activityVisible = false
                  return
                }
              }
              if (localStorage.isNotice === true || localStorage.isNotice === undefined) {
                this.formVisible = true
              } else {
                this.formVisible = false
                if (localStorage.activity === 'true') {
                  this.activityVisible = false
                  return
                } else if (localStorage.activity === 'false') {
                  // this.activityVisible = true
                  if (this.isHasActivity) {
                    this.activityVisible = true
                  }
                }
              }
            }
          })
        }
      })
    },
    clean() {
      // this.$store.commit('manager/selectIcon', '');
    },
    loadHandle(data) {
      this.loading = data
    },
    handleClose() {
      console.log(localStorage.activity)
      localStorage.cLoseDialog = 'false'
      this.formVisible = false
      if (localStorage.activity === 'false') {
        // this.activityVisible = true
        if (this.isHasActivity) {
          this.activityVisible = true
        }
      }
    },
    changeHandle() {
      if (this.forms.isNotice.length === 0) {
        localStorage.isNotice = true
      } else {
        localStorage.isNotice = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.upload-progress,
.unzip-progress{
    position: fixed;
    top: 50px;
    right: 30px;
    text-align: center;
    // background-color: #DCDCDC;
    // border-radius: 6px;
    .upload-icon{
      width: 16px;
      height: 16px;
    }
  }
   ::v-deep .el-dialog{
  border-radius: 20px;
}
.account-dialog{
  ::v-deep .el-dialog__header{
    padding: 10px !important;
  }
}
.dialog-footer{
      text-align: right;
      padding-top: 30px;
    }
    ::v-deep .el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    ::v-deep .el-dialog__body{
      padding: 30px;
    }
    ::v-deep .el-icon{
      color: #333;
    }
    ::v-deep th.el-table__cell{
		background-color: #F5F5F5;
	}
  ::v-deep .el-dialog{
    max-height: 600px;
    .el-dialog__body{
      max-height: 600px;
    }
  }
::v-deep .el-button--default{
    background-color: #fff;
    color: #437AEC;
   }
  ::v-deep .el-card.is-always-shadow{
    box-shadow: none;
  }
  ::v-deep .el-card{
    border: none;
  }
.er-code{
  text-align: center;
  img{
    width: 150px;
    height: 150px;
  }
}
.recharge-finish{
  text-align: right;
  span{
    cursor: pointer;
    color: #437AEC;
  }
}
.dialog-activity-container{
  ::v-deep .el-dialog__headerbtn{
    top: -3px;
    right: -5px;
  }
  ::v-deep .el-dialog__header{
    padding: 0;
    border: none;
  }
  ::v-deep .el-dialog__body{
    padding: 0;
  }
  ::v-deep .el-dialog{
    --el-dialog-background-color:none;
  }
  // ::v-deep .el-dialog__headerbtn .el-dialog__close{
  //   visibility: hidden;
  // }
  ::v-deep .el-dialog__footer{
    display: none;
  }
  ::v-deep .el-icon{
    color: #fff;
    font-size: 18px;
  }
  img{
    display: block;
    cursor: pointer;
  }
  img:hover{
    box-shadow: 3px 3px 6px 0 rgba(26, 53, 108, 0.2);
  }
}
.dialog-balance-container{
  .tips{
    margin-left: 32px;
  }
  .btn-box{
    text-align: center;
    ::v-deep .el-button{
      padding: 2px 21px;
      min-height: 26px;
      border: 1px solid #165DFF;
      color: #165DFF;
    }
  }
  .headers{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #000000;
    margin-bottom: 8px;
    .left{
      display: flex;
      align-items: center;
    }
    .recharge{
      a{
        color: #437AEC;
      }

      cursor: pointer;
    }
    img{
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  ::v-deep .el-dialog__headerbtn{
    display: none;
  }
  ::v-deep .el-dialog__header{
    padding: 0;
  }
  ::v-deep .el-dialog__body{
    padding: 20px 24px;
  }
  ::v-deep .el-dialog{
    border-radius: 6px;
  }
}

::v-deep .el-dialog__body{
  padding: 20px 45px 10px 45px;
}
// ::v-deep .el-checkbox{
//   color: #437aec;
// }
.tips{
  padding: 20px 0;
  font-size: 16px;
  // color: #437aec;
}
</style>
