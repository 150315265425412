<template>
  <div id="manualBareMetal" class="bare-metal">
    <div class="video">
      <img src="@/assets/manual/video14.png" alt="超算云桌面用户手册">
    </div>
    <div>
      <h1 style="padding-top:24px;">
        裸金属使用教程
      </h1>
    </div>
    <div class="content" style="padding-left:11px;">
      <div class="model-box">
        <div class="box-content">
          <div>
            1、从桌面选择弹性裸金属，双击或者右键选择启动进入应用界面。
          </div>
          <div class="img-box">
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div class="model-box">

        <div class="box-content">
          <div>
            2、在应用里边进行机器管理，包含查看机器列表，并有对应的操作：释放实例、ssh、重置密码、添加端口映射等。          </div>
          <div class="img-box">
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl2" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div class="model-box">
        <div class="box-content">
          <div>
            对应的具体操作如下图：
          </div>
          <div class="img-box">
            <viewer :images="imageUrl3" :options="options">
              <img :src="imageUrl3" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/bareMetal1-bg.png'),
      imageUrl2: require('../assets/manual/bareMetal2-bg.png'),
      imageUrl3: require('../assets/manual/bareMetal3-bg.png')
    }
  },
  methods: {
    showImagePreview(val) {
      if (document.body.getElementsByClassName('previewContainer').length !== 0) {
        document.body.removeChild(document.body.getElementsByClassName('previewContainer')[0])
      }
      const image = new Image()
      // image.src = this.imageUrl
      if (val === 1) {
        image.src = this.imageUrl1
      } else if (val === 2) {
        image.src = this.imageUrl2
      } else if (val === 3) {
        image.src = this.imageUrl3
      }
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div')
        previewContainer.className = 'previewContainer'
        previewContainer.style.position = 'fixed'
        previewContainer.style.top = 0
        previewContainer.style.bottom = 0
        previewContainer.style.left = 0
        previewContainer.style.right = 0
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContainer.style.display = 'flex'
        // previewContainer.style.justifyContent = 'center'
        previewContainer.style.alignItems = 'center'
        document.body.appendChild(previewContainer)
        // 在弹出层中添加图片
        const previewImage = document.createElement('img')
        // previewImage.src = this.imageUrl
        if (val == 1) {
          previewImage.src = this.imageUrl1
        } else if (val === 2) {
          previewImage.src = this.imageUrl2
        } else if (val === 3) {
          previewImage.src = this.imageUrl3
        }
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewContainer.appendChild(previewImage)
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer)
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.bare-metal{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
}
</style>
